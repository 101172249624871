import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Grid } from '@mui/material';
import GeneralFiltersContainer from '../../../../../../components/EmwGeneralFilters/GeneralFiltersContainer';
import { DrillToDetailsStyled } from './styled';
import EmwButton from '../../../../../../components/EmwButton/EmwButton';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import MoodAndDistribution from '../MoodDistribution/MoodAndDistribution';
import ConfidenceToDeliverDistribution from '../ConfidenceToDeliverDistribution/ConfidenceToDeliverDistribution';
import DetailsTable from '../DetailsTable/DetailsTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMcdDrillProcessData,
  getMcdDrillToData,
  getMcdDrillToPayload,
} from '../../../../redux/selectors';
import {
  setMcdDrillProcessData,
  setMcdDrillToData,
  setMcdDrillToPayload,
} from '../../../../redux/actions';
import { CHART_TYPE, transformProcessedData } from '../../utils';
import { selectedGeneralFilters } from '../../../../../../components/EmwGeneralFilters/redux/selectors';
import DatePeriod from '../DatePeriod/DatePeriod';
import useGeneralFilters from '../../../../../../components/EmwGeneralFilters/hooks/useGeneralFilters';
import { treeDataSelector } from '../../../../../header/redux/selectors';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';
import { computeURL, fetchOptions } from '../../../../../../../util/fetch';

export default function DrillToDetails() {
  const hzaContext = useContext(HzaContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { buildDrillToDetailsFilters, buildFiltersWithParam } = useGeneralFilters();
  const reqPayload = useSelector(getMcdDrillToPayload);
  const processData = useSelector(getMcdDrillProcessData);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const mcdData = useSelector(getMcdDrillToData);
  const treeData = useSelector(treeDataSelector);
  const [mood, setMood] = useState(transformProcessedData(processData, CHART_TYPE.MOOD));
  const [confidence, setConfidence] = useState(
    transformProcessedData(processData, CHART_TYPE.CONFIDENCE)
  );
  const orgsInTreeData = treeData.list && treeData.list.data && treeData.list.data.orgs;

  useEffect(() => {
    if (hzaContext.requestStatus.name === 'mcdData') {
      const response = hzaContext.requestStatus;
      dispatch(setMcdDrillToData(response.data));
    }
    if (hzaContext.requestStatus.name === 'mcdProcessedData') {
      const response = hzaContext.requestStatus;
      dispatch(setMcdDrillProcessData(response.data));
    }
  }, [hzaContext.requestStatus]);

  useEffect(() => {
    setMood(transformProcessedData(processData, CHART_TYPE.MOOD));
    setConfidence(transformProcessedData(processData, CHART_TYPE.CONFIDENCE));
  }, [processData]);

  useEffect(() => {
    if (treeData.list.hasOwnProperty('data') && treeData.list.data.regions.length) {
      buildDrillToDetailsFilters(treeData.list.data);
      buildFiltersWithParam(treeData.list.data, 'showInDrillToDetails');
    }
  }, [treeData.list.data]);

  useEffect(() => {
    const mcdDataApi = computeURL('mcd', 'mcdData');
    const mcdProcessDataApi = computeURL('mcd', 'mcdProcessedData');

    const mcdDataParams = fetchOptions({ method: 'POST' });
    const mcdProcessDataParams = fetchOptions({ method: 'POST' });
    mcdDataParams.body = JSON.stringify(reqPayload);
    mcdProcessDataParams.body = JSON.stringify(reqPayload);

    hzaContext.handleRequest(mcdDataApi, mcdDataParams, 'mcdData');
    hzaContext.handleRequest(mcdProcessDataApi, mcdDataParams, 'mcdProcessedData');
  }, [reqPayload]);

  useEffect(() => {
    const _payload = { ...reqPayload };
    _payload.organizationFilters.countries = selectedFilters.country.map(country => country.id);
    _payload.organizationFilters.gbus = selectedFilters.gbu.map(gbu => gbu.id);
    _payload.organizationFilters.bls = selectedFilters.bl.map(bl => bl.id);
    _payload.organizationFilters.ccs = selectedFilters.cc.map(cc => cc.id);
    _payload.orgs = orgsInTreeData;
    dispatch(setMcdDrillToPayload(_payload));
  }, [selectedFilters]);

  const handleBack = async () => {
    navigate('/moodandconfidence');
  };
  return (
    <GeneralFiltersContainer pt={'pt-xxs'}>
      <DrillToDetailsStyled className="flex flex-col flex-1 overflow-auto px-xs pb-xs">
        <div className="page-title my-m">
          <EmwButton
            label="Back"
            variant="ghost"
            color="neutral"
            leftIcon="arrow_back_ios"
            onClick={handleBack}
          />
          <EmwTypography classes="text-primary-500 ml-xs" fontSize="5" fontWeight="bold">
            Mood & Confidence to deliver - Drill to details
          </EmwTypography>
          <div className="ml-auto">
            <DatePeriod />
          </div>
        </div>
        <div className="row">
          <Grid container>
            <Grid item md={12} lg={6} className="pr-xs">
              <MoodAndDistribution data={mood} />
            </Grid>
            <Grid item md={12} lg={6} className="pl-xs">
              <ConfidenceToDeliverDistribution data={confidence} />
            </Grid>
          </Grid>
        </div>
        <div className="row  mt-m overflow-hidden flex-1">
          <div className="flex-1 bg-white-100">
            <DetailsTable tableData={mcdData} />
          </div>
        </div>
      </DrillToDetailsStyled>
    </GeneralFiltersContainer>
  );
}
