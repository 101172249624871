import React, { useEffect, useMemo, useState } from 'react';
import './index.css';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { useVirtualizer } from '@tanstack/react-virtual';
import Stars from '../Stars/Stars';
import moment from 'moment/moment';

export default function DetailsTable({ tableData }) {
  const tableContainerRef = React.useRef(null);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'startdate',
        header: 'Date',
        cell: info => {
          return moment(info.cell.getValue()).format('yyyy/DD/MM HH:mm:ss');
        },
        size: 100,
      },
      {
        accessorKey: 'gbu',
        header: 'GBU',
        size: 70,
      },
      {
        accessorKey: 'bl',
        header: 'Bl',
        size: 70,
      },
      {
        accessorKey: 'cc',
        header: 'CC',
      },
      {
        accessorKey: 'country',
        header: 'Country',
      },
      {
        accessorFn: row => row.mood,
        id: 'mood',
        cell: info => {
          return <Stars value={info.cell.getValue()} size="small" />;
        },
        header: 'Mood',
      },
      {
        accessorKey: 'moodcomment',
        header: 'Comment',
        size: 200,
      },
      {
        accessorFn: row => row.confidence,
        id: 'confidence',
        cell: info => {
          return <Stars value={info.cell.getValue()} size="small" />;
        },
        header: () => <span>Confidence</span>,
      },
      {
        accessorKey: 'confidencecomment',
        header: 'Comment',
        size: 200,
      },
    ],
    []
  );

  const table = useReactTable({
    data: tableData,
    columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <div ref={tableContainerRef} className="table-container">
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="flex w-full">
              {headerGroup.headers.map(header => {
                const style = {
                  width: header.getSize(),
                };

                if (['moodcomment', 'confidencecomment'].includes(header.id)) {
                  style.flexGrow = 1;
                }

                return (
                  <th key={header.id} className="flex text-primary-50 text-scale-8" style={style}>
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          className="text-scale-8 font-thin bg-white-100"
          style={{
            display: 'grid',
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: 'relative', //needed for absolute positioning of rows
          }}
        >
          {rowVirtualizer.getVirtualItems().map(virtualRow => {
            const row = rows[virtualRow.index];
            return (
              <tr
                data-index={virtualRow.index} //needed for dynamic row height measurement
                ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                key={row.id}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  width: '100%',
                }}
              >
                {row.getVisibleCells().map(cell => {
                  const style = {
                    width: cell.column.getSize(),
                  };

                  if (['moodcomment', 'confidencecomment'].includes(cell.column.id)) {
                    // style.width = '100%';
                    style.flexGrow = 1;
                  }

                  return (
                    <td key={cell.id} className="flex" style={style}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
