import React, { useCallback, useMemo, useState } from 'react';
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

/**
 * @TODO: La click pe cuvand trebuie executate requesturile de: mcdProcessData si de
 * @param data
 * @param width
 * @param height
 * @param type
 * @returns {Element}
 * @constructor
 */
export default function EmwWordCount({ data, width, height, type }) {
  const [selectedWord, setSelectedWord] = useState(null);
  const fontSize = useCallback(word => Math.log2(word.value) * 10, []);
  const rotate = useCallback(word => word.value % 1, []);
  const fill = useCallback((d, i) => schemeCategory10ScaleOrdinal(i), []);

  const onWordClick = useCallback(word => {
    console.log(`onWordClick:`, word);
  }, []);

  return (
    <WordCloud
      data={data}
      width={width}
      height={height}
      fontSize={fontSize}
      spiral="archimedean"
      rotate={rotate}
      padding={5}
      random={Math.random}
      fill={fill}
      onWordClick={onWordClick}
      key="word-cloud"
      transitionDuration={0}
    />
  );
}
