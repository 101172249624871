import React, { useEffect, useState } from 'react';
import UsageMonitoringDrillToDetail from './UsageMonitoringDrillToDetail';
import UsageMonitoringDrillThrough from './UsageMonitoringDrillThrough';
import UsageMonitoringGraph from './UsageMonitoringGraph';
import { getUsageMonitoringIndicatorsApi } from '../../../redux/api';
import TimeFrame from '../../../../../components/EmwCharts/components/Filters/components/TimeFrame/TimeFrame';
import EmwTypography from '../../../../../components/EmwTypography/EmwTypography';
import { StyledUsageMonitoring } from '../styled';
import EmwButton from '../../../../../components/EmwButton/EmwButton';
import EmwIcon from '../../../../../components/EmwIcon/EmwIcon';
import GeneralFiltersContainer from '../../../../../components/EmwGeneralFilters/GeneralFiltersContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  rolesFiltersSelector,
  selectedDrillToDetailFilters,
  selectedGeneralFilters,
  servicesFiltersSelector,
} from '../../../../../components/EmwGeneralFilters/redux/selectors';
import useAppSnackbar from '../../../../../hooks/useAppSnankbar';
import {
  setLoading,
  setSelectedFilters,
} from '../../../../../components/EmwGeneralFilters/redux/actions';
import moment from 'moment';
import { getFiltersPayload } from '../../../../../components/EmwGeneralFilters/utils';
import useGeneralFilters from '../../../../../components/EmwGeneralFilters/hooks/useGeneralFilters';
import { treeDataSelector } from '../../../../header/redux/selectors';
import { USER_ACTIVITY_SERVICES } from '../../../../userActivity/constants';
import { userActivity } from '../../../../userActivity/redux/actions';
import { v4 as uuidv4 } from 'uuid';

export default function UsageMonitoring() {
  const [activeUsersData, setActiveUsersData] = useState([]);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const selectedDrillFilters = useSelector(selectedDrillToDetailFilters);

  const [isUsageMonitoring, setIsUsageMonitoring] = useState(true);
  const [isDrillToDetails, setIsDrillToDetails] = useState(false);
  const [isDrillToDetailsDisabled, setIsDrillToDetailsDisabled] = useState(false);
  const [isDrillThrough, setIsDrillThrough] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const dates = [];
  const { buildFilters, buildDrillToDetailsFilters, buildFiltersWithParam } = useGeneralFilters();
  const treeData = useSelector(treeDataSelector);
  const rolesFilters = useSelector(rolesFiltersSelector);
  const servicesFilters = useSelector(servicesFiltersSelector);
  const generateDateOptions = months => {
    for (let i = 0; i < months; i++) {
      const date = moment().subtract(i, 'months');
      dates.push(date.format('MMM YY'));
    }
    return dates;
  };
  const currentMonth = generateDateOptions(21)[0];
  const defaultPeriod = '12 Months';
  const [stateData, setStateData] = useState({
    organizationFilters: getFiltersPayload(
      isDrillToDetails ? selectedDrillFilters : selectedFilters
    ),
    periodFilters: {
      period: defaultPeriod,
      date: currentMonth,
    },
    roles: rolesFilters,
    services: servicesFilters,
    restricted: false,
  });
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const handleClickBack = () => {
    window.hash = '';
    setIsOpen(!isOpen);
    window.location.hash = '';
    setIsUsageMonitoring(true);
    setIsDrillToDetails(false);
    setIsDrillThrough(false);
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.USAGE_MONITORING,
        details: 'VIEW',
        uri: '',
      })
    );
  };

  const handleClickDrillToDetails = () => {
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.USAGE_MONITORING_DRILL_TO,
        details: 'VIEW',
        uri: '',
      })
    );
    window.location.hash = 'drilltodetails';
    setIsUsageMonitoring(false);
    setIsDrillThrough(false);
    setIsDrillToDetails(!isDrillToDetails);
  };

  const handleClickDrillThrough = () => {
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.USAGE_MONITORING_DRILL_THROUGH,
        details: 'VIEW',
        uri: '',
      })
    );
    window.location.hash = 'drillthrough';
    setIsUsageMonitoring(false);
    setIsDrillThrough(!isDrillThrough);
    setIsDrillToDetails(false);
  };

  useEffect(() => {
    if (isDrillToDetails) {
      // buildDrillToDetailsFilters(treeData.list.data);
      buildFiltersWithParam(treeData.list.data, 'showInRestricted');
    } else if (
      treeData.list.hasOwnProperty('data') &&
      selectedFilters.country.length === 0 &&
      selectedFilters.bl.length === 0 &&
      selectedFilters.cc.length === 0 &&
      selectedFilters.gbu.length === 0
    ) {
      // buildFilters(treeData.list.data);
      buildFiltersWithParam(treeData.list.data);
    }
  }, [isDrillToDetails]);

  useEffect(() => {
    setStateData(currentState => {
      return {
        ...currentState,
        organizationFilters: getFiltersPayload(
          isDrillToDetails ? selectedDrillFilters : selectedFilters
        ),
        roles: rolesFilters,
        services: servicesFilters,
      };
    });
  }, [selectedFilters, selectedDrillFilters, rolesFilters, servicesFilters]);

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));

      try {
        const response = await getUsageMonitoringIndicatorsApi(stateData);
        setActiveUsersData(response.data.chart || []);
        setIsDrillToDetailsDisabled(response.data.drillToDetails);
        if (!response.data) {
          snackbar.show(response.message, 'error');
        }
      } catch (reason) {
        snackbar.show(reason.message || reason.statusText, 'warning');
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [stateData, isDrillToDetails]);

  return (
    <GeneralFiltersContainer>
      <div
        className="flex flex-col flex-1 overflow-auto px-xs pb-xs"
        style={{ backgroundColor: '#F7F7F9' }}
      >
        <div className="title-and-period flex mb-m items-center">
          {isUsageMonitoring && (
            <EmwTypography classes="text-primary-500" fontSize={4} fontWeight="bold">
              Usage Monitoring
            </EmwTypography>
          )}
          {isDrillToDetails && (
            <StyledUsageMonitoring>
              <div className="back flex items-center">
                <div className="flex justify-center items-center">
                  <EmwButton
                    variant="ghost"
                    size="small"
                    title="Click here to go back to the previous page in this report"
                    id="drillToDetail"
                    onClick={handleClickBack}
                  >
                    <EmwIcon
                      icon="arrow_back_ios"
                      variant="outlined"
                      size="xsmall"
                      classes="pl-l"
                    />
                    <EmwTypography fontSize="18" classes="text-black-100">
                      Back
                    </EmwTypography>
                  </EmwButton>
                </div>
                <EmwTypography classes="text-primary-500" fontSize={5} fontWeight="bold">
                  Usage Monitoring - drill to detail
                </EmwTypography>
              </div>
            </StyledUsageMonitoring>
          )}
          {isDrillThrough && (
            <StyledUsageMonitoring>
              <div className="back flex items-center">
                <div className="flex justify-center items-center">
                  <EmwButton
                    variant="ghost"
                    size="small"
                    title="Click here to go back to the previous page in this report"
                    id="drillThrough"
                    onClick={handleClickBack}
                  >
                    <EmwIcon
                      icon="arrow_back_ios"
                      variant="outlined"
                      size="xsmall"
                      classes="pl-l"
                    />
                    <EmwTypography fontSize="18" classes="text-black-100">
                      Back
                    </EmwTypography>
                  </EmwButton>
                </div>
                <EmwTypography classes="text-primary-500" fontSize={5} fontWeight="bold">
                  Usage Monitoring - drill through
                </EmwTypography>
              </div>
            </StyledUsageMonitoring>
          )}

          <div className="ml-auto">
            <TimeFrame
              filters={stateData}
              setFilters={setStateData}
              setActiveUsersData={setActiveUsersData}
              isUsageMonitoring={true}
              defaultPeriod={defaultPeriod}
              defaultDate={currentMonth}
            />
          </div>
        </div>

        {isUsageMonitoring && (
          <UsageMonitoringGraph
            isDrillToDetailsDisabled={isDrillToDetailsDisabled}
            activeUsersData={activeUsersData}
            handleClick={handleClick}
            handleClickDrillToDetails={handleClickDrillToDetails}
            handleClickDrillThrough={handleClickDrillThrough}
          />
        )}
        {isDrillToDetails && (
          <UsageMonitoringDrillToDetail
            payload={stateData}
            handleClickBack={handleClickBack}
            stateData={stateData}
          />
        )}
        {isDrillThrough && (
          <UsageMonitoringDrillThrough
            activeUsersData={activeUsersData}
            payload={stateData}
            handleClickBack={handleClickBack}
            stateData={stateData}
          />
        )}
      </div>
    </GeneralFiltersContainer>
  );
}
