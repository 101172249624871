import React from 'react';
import { styled } from '@mui/material/styles';
import { QtmButton } from '@qtm/react';

const Root = styled('div')(() => ({
  display: 'flex',
}));

export default function EmwButton({
  size,
  disabled,
  variant,
  inputId,
  id,
  classes,
  onClick,
  title,
  children,
  color,
  label,
  leftIcon,
}) {
  const onButtonClick = event => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <Root>
      {disabled && (
        <QtmButton
          title={title ? title : ''}
          disabled={disabled}
          variant={variant}
          size={size}
          inputId={inputId}
          props={{ id: id ? id : '' }}
          classes={classes}
          label={label}
          leftIcon={leftIcon}
        >
          {children}
        </QtmButton>
      )}

      {!disabled && (
        <QtmButton
          title={title ? title : ''}
          disabled={disabled}
          variant={variant}
          size={size}
          inputId={inputId}
          classes={classes}
          color={color ? color : 'primary'}
          onClick={onButtonClick}
          props={{ id: id ? id : '' }}
          label={label}
          leftIcon={leftIcon}
        >
          {children}
        </QtmButton>
      )}
    </Root>
  );
}
