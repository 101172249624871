import { MODULE_NAME } from '../constants';
import { createSelector } from '@reduxjs/toolkit';
import * as actions from './actions';
import {
  MCD_DRILL_PROCESS_DATA,
  MCD_DRILL_TO_PAYLOAD,
  REPORT_FILTERS,
  REPORT_FILTERS_HZA,
} from './actions';

export const powerBi = state => state[MODULE_NAME];
export const chartsSelector = createSelector(powerBi, state => state[actions.CHARTS]);
export const alertsSelector = createSelector(powerBi, state => state[actions.ALERTS]);
export const commentsSelector = createSelector(powerBi, state => state[actions.COMMENTS]);
export const createTargetSelector = createSelector(powerBi, state => state[actions.CREATE_TARGET]);
export const shouldRenderLinesSelector = createSelector(
  powerBi,
  state => state[actions.SHOULD_RENDER_LINES]
);
export const canAddTargetsSelector = createSelector(
  powerBi,
  state => state[actions.CAN_ADD_TARGETS]
);
export const canAddAlertsSelector = createSelector(powerBi, state => state[actions.CAN_ADD_ALERTS]);
export const userOrgsSelector = createSelector(powerBi, state => state[actions.USER_ORGANIZATIONS]);
export const alertHistoryModalSelector = createSelector(
  powerBi,
  state => state[actions.ALERT_HISTORY_MODAL]
);
export const commentHistoryModalSelector = createSelector(
  powerBi,
  state => state[actions.MY_COMMENTS_HISTORY_MODAL]
);
export const powerBiInitializedSelector = createSelector(
  powerBi,
  state => state[actions.POWER_BI_INITIALIZED]
);
export const selectedReportSelector = createSelector(
  powerBi,
  state => state[actions.SELECTED_REPORT]
);
export const isDrilledSelector = createSelector(powerBi, state => state[actions.IS_DRILLED]);
export const commentsRolesSelector = createSelector(
  powerBi,
  state => state[actions.COMMENTS_ROLES]
);
export const accessTokenSelector = createSelector(powerBi, state => state[actions.ACCESS_TOKEN]);
export const reportFiltersSelector = createSelector(
  powerBi,
  state => state[actions.REPORT_FILTERS]
);
export const reportFiltersHzaSelector = createSelector(
  powerBi,
  state => state[actions.REPORT_FILTERS_HZA]
);
export const embeddedUrlSelector = createSelector(powerBi, state => state[actions.EMBEDDED_URL]);

export const getMcdDrillToPayload = createSelector(
  powerBi,
  state => state[actions.MCD_DRILL_TO_PAYLOAD]
);
export const getMcdDrillToData = createSelector(powerBi, state => state[actions.MCD_DRILL_TO_DATA]);
export const getMcdDrillProcessData = createSelector(
  powerBi,
  state => state[actions.MCD_DRILL_PROCESS_DATA]
);
