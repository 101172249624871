import { styled } from '@mui/material';

export const DrillToDetailsStyled = styled('div')(({ theme }) => {
  return {
    backgroundColor: '#F2F2F2',

    '.page-title': {
      display: 'flex',
      alignItems: 'center',

      'qtm-button': {
        span: {
          fontWeight: 'bold',
        },
      },
      'qtm-icon': {
        margin: '0!important',
      },
    },

    '.row': {
      display: 'flex',

      '.mood-and-distribution': {
        table: {
          fontSize: '16px',
          thead: {
            td: {
              backgroundColor: '#0F186E',
              color: 'white',
              padding: '3px 5px',
              textAlign: 'center',
              width: '120px',
              borderRight: '1px solid white',
            },
          },
          tbody: {
            td: {
              padding: '0 5px',
              textAlign: 'center',
              height: '30px',
              fontSize: '20px',
            },
          },
        },

        '.chart-section': {
          maxHeight: '300px',
        },
      },
    },
  };
});
