import React, { useContext, useEffect, useState } from 'react';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import Rating from '@mui/material/Rating';
import { BarChart } from '../../../../../../components/EmwCharts';
import { abbreviateNumber } from '../../utils';
import EmwWordCount from '../../../../../../components/EmwCharts/components/EmwWordCount/EmwWordCount';
import DistributionTable from '../DistributionTable/DistributionTable';
import { useSelector } from 'react-redux';
import { getMcdDrillToPayload } from '../../../../redux/selectors';
import { getMcdConfidenceWordsApi } from '../../../../redux/api';
import { computeURL, fetchOptions } from '../../../../../../../util/fetch';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';
// import EmwWordCloud from '../../../../../../components/EmwCharts/components/EmwWordCount/EmwWordCloud';

export default function ConfidenceToDeliverDistribution({ data }) {
  const hzaContext = useContext(HzaContext);
  const reqPayload = useSelector(getMcdDrillToPayload);
  const [words, setWords] = useState([]);

  useEffect(() => {
    if (hzaContext.requestStatus.name === 'confidenceWords') {
      const response = hzaContext.requestStatus;
      setWords(response.data);
    }
  }, [hzaContext.requestStatus]);

  useEffect(() => {
    const mcdConfidenceWordsApi = computeURL('mcd', 'confidenceWords');
    const mcdConfidenceWordParams = fetchOptions({ method: 'POST' });
    mcdConfidenceWordParams.body = JSON.stringify(reqPayload);
    hzaContext.handleRequest(mcdConfidenceWordsApi, mcdConfidenceWordParams, 'confidenceWords');
  }, [reqPayload]);
  return (
    <div className="flex bg-white-100 mood-and-distribution flex-1">
      <div className="flex flex-col flex-1">
        <EmwTypography classes="py-xxs pl-xxs">Confidence to deliver distribution</EmwTypography>
        <DistributionTable data={data.distribution} />
        <div className="chart-section flex flex-1 pl-s">
          <BarChart
            hasShadow={false}
            data={data.chart}
            unit=""
            margin={{ top: 10, right: 0, bottom: 38, left: 35 }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Confidence Number of Marks',
              legendPosition: 'middle',
              legendOffset: -31,
              format: value => abbreviateNumber(value),
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Confidence to deliver',
              legendPosition: 'middle',
              legendOffset: 25,
              truncateTickAt: 0,
            }}
          />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <EmwTypography classes="py-xxs pl-xxs">Comment</EmwTypography>
        <div className="flex-1 overflow-hidden p-m">
          <EmwWordCount data={words} height={500} />
          {/* <EmwWordCloud data={words} height={500} width={700} /> */}
        </div>
      </div>
    </div>
  );
}
