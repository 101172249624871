import React from 'react';
import { Rating, styled } from '@mui/material';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#0F186E',
  },
  '& .MuiRating-iconHover': {
    color: '#0F186E',
  },
});

export default function Stars({ value, size }) {
  return <StyledRating size={size} value={value} readOnly color="black" />;
}
