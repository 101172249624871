import React, { useEffect, useState } from 'react';
import { getDateMinus, getPeriodList, getRangeList } from '../../utils';
import EmwDropdownSelect from '../../../../../../components/EmwDropdownSelect/EmwDropdownSelect';
import EmwDropdownSelectOption from '../../../../../../components/EmwDropdownSelect/EmwDropdownSelectOption';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { useDispatch, useSelector } from 'react-redux';
import { getMcdDrillToPayload } from '../../../../redux/selectors';
import { setMcdDrillToPayload } from '../../../../redux/actions';
import { treeDataSelector } from '../../../../../header/redux/selectors';

const monthsList = getRangeList();
const periodList = getPeriodList();

export default function DatePeriod() {
  const dispatch = useDispatch();
  const reqPayload = useSelector(getMcdDrillToPayload);
  const [selectedDate, setSelectedDate] = useState(monthsList[0].label);
  const [selectedPeriod, setSelectedPeriod] = useState(periodList[0].value);
  const treeData = useSelector(treeDataSelector);

  const handleDateChange = event => {
    const value = event.target.value;
    const selectedValue = monthsList.find(month => month.label === value);
    setSelectedDate(value);

    const _reqPayload = { ...reqPayload };

    _reqPayload.startDate = getDateMinus(selectedValue.startDate, parseInt(selectedPeriod) - 2);
    _reqPayload.endDate = selectedValue.endDate;

    dispatch(setMcdDrillToPayload(_reqPayload));
  };

  const handlePeriodChange = event => {
    const value = event.target.value;
    setSelectedPeriod(value);
    const dateObj = monthsList.find(month => month.label === selectedDate);
    const _reqPayload = { ...reqPayload };

    _reqPayload.startDate = getDateMinus(dateObj.startDate, parseInt(value) - 2);
    _reqPayload.orgs = treeData.list.data.orgs;
    dispatch(setMcdDrillToPayload(_reqPayload));
  };

  return (
    <div className="flex items-center">
      <EmwTypography fontSize={9} classes="font-black mr-xxs">
        DATE
      </EmwTypography>

      <div style={{ width: '130px' }}>
        <EmwDropdownSelect size="small" value={selectedDate} onValueChanged={handleDateChange}>
          <div style={{ maxHeight: '200px', overflow: 'auto' }}>
            {monthsList.map(month => {
              return (
                <EmwDropdownSelectOption key={month.value} value={month.label}>
                  {month.label}
                </EmwDropdownSelectOption>
              );
            })}
          </div>
        </EmwDropdownSelect>
      </div>

      <EmwTypography fontSize={9} classes="font-black mx-xxs">
        PERIOD
      </EmwTypography>

      <div style={{ width: '100px' }}>
        <EmwDropdownSelect size="small" value={selectedPeriod} onValueChanged={handlePeriodChange}>
          <>
            {periodList.map(period => {
              return (
                <EmwDropdownSelectOption key={period.value} value={period.value}>
                  {period.value}
                </EmwDropdownSelectOption>
              );
            })}
          </>
        </EmwDropdownSelect>
      </div>
    </div>
  );
}
