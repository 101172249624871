import * as actions from './actions';
import {
  CHARTS,
  ENGINEERING_COMPETITIVENESS,
  MOOD_AND_CONFIDENCE,
  TALENTS_AND_CULTURES,
} from '../constants';
import { initialProps } from '../../../../util/reducer';
import { getDateMinus, getRangeList } from '../components/MoodAndConfidence/utils';

const TITLE_CONFIDENCE_TO_DELIVER = 'Confidence to deliver';
const TITLE_DOTD = 'DOTD (Development On Time Delivery)';
const TITLE_DAVD = 'DAVD (Development Average Delay)';
const TITLE_KEY_PROFILE_FIT = 'Key Profile Fit';
const TITLE_SKILL_COVERAGE = 'Skill Coverage';

const getInitialCurrentDates = () => {
  const { startDate, endDate } = getRangeList()[0];

  return {
    endDate,
    startDate: getDateMinus(startDate, 0),
  };
};

const { startDate, endDate } = getInitialCurrentDates();

console.log('{ startDate, endDate }', { startDate, endDate });

export default {
  [actions.CREATE_TARGET]: {
    ...initialProps,
    list: {},
  },
  [actions.SHOULD_RENDER_LINES]: true,
  [actions.USER_ORGANIZATIONS]: [],
  [actions.CAN_ADD_TARGETS]: false,
  [actions.CAN_ADD_ALERTS]: false,
  [actions.GENERAL_FILTERS]: [],
  [actions.POWER_BI_INITIALIZED]: false,
  [actions.ALERT_HISTORY_MODAL]: {
    isOpen: false,
    chart: {
      parent: '',
      name: '',
    },
  },
  [actions.MY_COMMENTS_HISTORY_MODAL]: {
    isOpen: false,
    chart: {
      parent: '',
      name: '',
    },
  },
  [actions.ALERTS]: {
    [MOOD_AND_CONFIDENCE]: {
      [CHARTS.MOOD]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 295,
        hasDecimals: 2,
        title: 'Mood',
        key: 'mood',
        values: [
          // id: 90789, type: 'above', period: '1', value: 2.2
        ],
      },
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 295,
        hasDecimals: 2,
        key: 'confidenceToDeliver',
        title: TITLE_CONFIDENCE_TO_DELIVER,
        values: [
          // id: 90789, type: 'above', period: '1', value: 2.2
        ],
      },
    },
    [ENGINEERING_COMPETITIVENESS]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 180,
        hasDecimals: 2,
        title: TITLE_CONFIDENCE_TO_DELIVER,
        key: 'confidenceToDeliver',
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.DOTD]: {
        period: 1,
        unit: '%',
        min: 0,
        max: 100,
        xMin: 75,
        xDiff: 45,
        yMin: 10,
        yMax: 200,
        title: TITLE_DOTD,
        key: CHARTS.DOTD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 8,
        nrOfBars: 12,
      },
      [CHARTS.DAVD]: {
        period: 1,
        unit: 'days',
        min: 0,
        max: 300,
        xMin: 65,
        xDiff: 45,
        yMin: 20,
        yMax: 150,
        title: TITLE_DAVD,
        key: CHARTS.DAVD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        nrOfBars: 12,
      },
    },
    [TALENTS_AND_CULTURES]: {
      [CHARTS.MOOD]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        xMin: 78,
        xDiff: 78,
        yMin: 10,
        yMax: 180,
        hasDecimals: 2,
        title: 'Mood',
        key: 'mood',
        parent: TALENTS_AND_CULTURES,
        values: [],
      },
      [CHARTS.KEY_PROFILE_FIT]: {
        period: 2,
        unit: 'days',
        min: 0,
        max: 600,
        yMin: 25,
        yMax: 175,
        key: 'keyProfileFit',
        title: TITLE_KEY_PROFILE_FIT,
        parent: TALENTS_AND_CULTURES,
        values: [],
      },
      [CHARTS.SKILL_COVERAGE]: {
        period: 2,
        unit: 'days',
        min: 0,
        max: 600,
        yMin: 40,
        yMax: 205,
        key: 'skillCoverage',
        title: TITLE_SKILL_COVERAGE,
        parent: TALENTS_AND_CULTURES,
        values: [],
      },
    },
  },
  [actions.CHARTS]: {
    [MOOD_AND_CONFIDENCE]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 75 : 90,
        xDiff: new Date().getMonth() % 2 === 1 ? 78 : 88,
        yMin: 10,
        yMax: 295,
        key: 'confidenceToDeliver',
        title: TITLE_CONFIDENCE_TO_DELIVER,
        parent: MOOD_AND_CONFIDENCE,
        values: [0, 0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.MOOD]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 75 : 90,
        xDiff: new Date().getMonth() % 2 === 1 ? 78 : 88,
        yMin: 10,
        yMax: 295,
        title: 'Mood',
        key: 'mood',
        parent: MOOD_AND_CONFIDENCE,
        values: [0, 0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
    },
    [ENGINEERING_COMPETITIVENESS]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 70 : 85,
        xDiff: new Date().getMonth() % 2 === 1 ? 80 : 90,
        yMin: 10,
        yMax: 180.7,
        title: TITLE_CONFIDENCE_TO_DELIVER,
        key: 'confidenceToDeliver',
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.DOTD]: {
        period: 1,
        unit: '%',
        min: 0,
        max: 100,
        hasDecimals: 0,
        xMin: 68,
        xDiff: 45,
        yMin: 10,
        yMax: 200,
        title: TITLE_DOTD,
        key: CHARTS.DOTD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        editableFrom: 8,
        nrOfBars: 12,
      },
      [CHARTS.DAVD]: {
        period: 1,
        unit: 'days',
        min: 0,
        max: 300,
        hasDecimals: 0,
        xMin: 65,
        xDiff: 45,
        yMin: 20,
        yMax: 150,
        title: TITLE_DAVD,
        key: CHARTS.DAVD,
        parent: ENGINEERING_COMPETITIVENESS,
        values: [],
        nrOfBars: 12,
      },
    },
    [TALENTS_AND_CULTURES]: {
      [CHARTS.MOOD]: {
        period: 2,
        unit: '',
        min: 1,
        max: 5,
        hasDecimals: 2,
        xMin: new Date().getMonth() % 2 === 1 ? 75 : 90,
        xDiff: new Date().getMonth() % 2 === 1 ? 78 : 88,
        yMin: 0,
        yMax: 190,
        title: 'Mood',
        key: 'mood',
        parent: TALENTS_AND_CULTURES,
        values: [0, 0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: new Date().getMonth() % 2 === 1 ? 7 : 6,
      },
      [CHARTS.KEY_PROFILE_FIT]: {
        period: 2,
        unit: '',
        min: 0,
        max: 600,
        hasDecimals: 0,
        xMin: 72,
        xDiff: 110,
        yMin: 35,
        yMax: 175,
        title: TITLE_KEY_PROFILE_FIT,
        key: 'keyProfileFit',
        parent: TALENTS_AND_CULTURES,
        values: [0, 0, 0, 0, 0],
        editableFrom: 4,
        nrOfBars: 5,
      },
      [CHARTS.SKILL_COVERAGE]: {
        period: 2,
        unit: '',
        min: 0,
        max: 600,
        hasDecimals: 0,
        xMin: 88,
        xDiff: 104,
        yMin: 35,
        yMax: 200,
        title: TITLE_SKILL_COVERAGE,
        key: 'skillCoverage',
        parent: TALENTS_AND_CULTURES,
        values: [0, 0, 0, 0, 0],
        editableFrom: 3,
        nrOfBars: 5,
      },
    },
  },
  [actions.COMMENTS]: {
    [MOOD_AND_CONFIDENCE]: {
      [CHARTS.MOOD]: {
        parent: MOOD_AND_CONFIDENCE,
        key: CHARTS.MOOD,
        title: 'Mood',
        values: [],
      },
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        parent: MOOD_AND_CONFIDENCE,
        key: CHARTS.CONFIDENCE_TO_DELIVER,
        title: TITLE_CONFIDENCE_TO_DELIVER,
        values: [],
      },
    },
    [ENGINEERING_COMPETITIVENESS]: {
      [CHARTS.CONFIDENCE_TO_DELIVER]: {
        parent: ENGINEERING_COMPETITIVENESS,
        key: CHARTS.CONFIDENCE_TO_DELIVER,
        title: TITLE_CONFIDENCE_TO_DELIVER,
        values: [],
      },
      [CHARTS.DOTD]: {
        parent: ENGINEERING_COMPETITIVENESS,
        key: CHARTS.DOTD,
        title: TITLE_DOTD,
        values: [],
      },
      [CHARTS.DAVD]: {
        parent: ENGINEERING_COMPETITIVENESS,
        key: CHARTS.DAVD,
        title: TITLE_DAVD,
        values: [],
      },
    },
    [TALENTS_AND_CULTURES]: {
      [CHARTS.MOOD]: {
        parent: TALENTS_AND_CULTURES,
        key: CHARTS.MOOD,
        title: 'Mood',
        values: [],
      },
      [CHARTS.KEY_PROFILE_FIT]: {
        parent: TALENTS_AND_CULTURES,
        key: CHARTS.KEY_PROFILE_FIT,
        title: TITLE_KEY_PROFILE_FIT,
        values: [],
      },
      [CHARTS.SKILL_COVERAGE]: {
        parent: TALENTS_AND_CULTURES,
        key: CHARTS.SKILL_COVERAGE,
        title: TITLE_SKILL_COVERAGE,
        values: [],
      },
    },
  },
  [actions.SELECTED_REPORT]: null,
  [actions.IS_DRILLED]: false,
  [actions.COMMENTS_ROLES]: {
    canAddActionPlan: false,
    canAddComment: false,
    canAddRootCause: false,
    canMakePublic: false,
  },
  [actions.ACCESS_TOKEN]: null,
  [actions.REPORT_FILTERS]: null,
  [actions.REPORT_FILTERS_HZA]: null,
  [actions.EMBEDDED_URL]: '',

  [actions.MCD_DRILL_TO_PAYLOAD]: {
    organizationFilters: {
      ccs: [],
      bls: [],
      gbus: [],
      countries: [],
    },
    startDate: startDate,
    endDate: endDate,
    orgs: [],
    projects: [],
    moodWords: [],
    confidenceWords: [],
  },
  [actions.MCD_DRILL_TO_DATA]: [],
  [actions.MCD_DRILL_PROCESS_DATA]: {
    total: 0,
    moodavg: 0,
    confidenceavg: 0,
    moodcoments: 0,
    confidencecoments: 0,
    m1: 0,
    m2: 0,
    m3: 0,
    m4: 0,
    m5: 0,
    c1: 0,
    c2: 0,
    c3: 0,
    c4: 0,
    c5: 0,
  },
};
