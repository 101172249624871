import reducer from './reducer';
import apiMiddleware from './apiMiddleware';
import loadingIndicatorMiddleware from './loadingIndicatorMiddleware';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import userActivityMiddleware from './V2/features/userActivity/redux/userActivityMiddleware';

// const middlewares = [userActivityMiddleware, apiMiddleware, loadingIndicatorMiddleware, logger];
const middlewares = [userActivityMiddleware, apiMiddleware, loadingIndicatorMiddleware];

export default configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middlewares),
});
